import { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import TriangleIcon from "../../assets/icons/Triangle";
import phoneCodes from "../../assets/phoneCodes.json";

interface Props {
  title: string;
  name?: string;
  type?: string;
  placeholder: string;
  phoneCode?: string;
  onChangeCode: (e: string) => void;
  value: string;
  onChange: (e: string) => void;
  error?: {
    message?: string;
    touched?: boolean;
  };
}
export default function Phoneinput(props: Props) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Container>
      {props.error?.message && props.error?.touched && (
        <ErrorText id={props.name + "_error"}>
          *ERROR: {props.error.message}
        </ErrorText>
      )}
      <TitleContainer>
        <Label isfocused={isFocused}>{props.title}</Label>
        <TopLine isfocused={isFocused} />
      </TitleContainer>
      <InputContainer isfocused={isFocused}>
        <Select
          value={props.phoneCode}
          onChange={props.onChangeCode}
          options={
            phoneCodes.map((code) => ({
              value: code,
              text: code,
            })) || []
          }
        />
        <Separator isfocused={isFocused} />
        <Input
          type={"tel"}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          placeholder={props.placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </InputContainer>
    </Container>
  );
}
interface SelectProps {
  value?: string;
  onChange: (e: string) => void;
  options: {
    value: string;
    text: string;
  }[];
}

function Select(props: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  function handleSelectOption(option: string) {
    setIsOpen(false);
    props.onChange(option);
  }

  return (
    <SelectContainer onClick={() => setIsOpen(!isOpen)} ref={ref}>
      <SelectSubContainer>
        <SelectedValue>{props.value}</SelectedValue>
        <TriangleIcon />
        {isOpen && (
          <OptionsContainer>
            {props.options?.map((option, index) => (
              <Option
                key={index}
                onClick={() => handleSelectOption(option.value)}
              >
                {option.text}
              </Option>
            ))}
          </OptionsContainer>
        )}
      </SelectSubContainer>
    </SelectContainer>
  );
}
const SelectContainer = styled.div`
  width: 120px;
  position: relative;
  cursor: pointer;
  user-select: none;
`;
const SelectSubContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  background: none;
`;
const SelectedValue = styled.div`
  display: flex;
  flex: 1;
`;
const OptionsContainer = styled.div`
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  border: 1px solid #ff818d;
  border-top: none;
  background: #93202b;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  max-height: 300px;
  overflow-y: auto;
`;
const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #fff2;
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
`;
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
`;
const Label = styled.label<{ isfocused: boolean }>`
  font-size: 18px;
  font-weight: 600;
  transition: color 0.5s ease;
  color: ${(p) => (p.isfocused ? "#FFFFFF" : "#ed283c")};
  margin-left: 12px;
  margin-right: 4px;
`;

const InputContainer = styled.div<{ isfocused: boolean }>`
  display: flex;
  width: 100%;
  transition: border-color 0.5s ease;
  border: 1px solid #ed283c;
  border-top: none;
  border-radius: 10px 1px 10px 1px;
  margin-top: 17px;
  margin-bottom: 10px;
  transition: border 0.5s ease;
  ${(p) =>
    p.isfocused &&
    `border: 1px solid #767676;
    border-top: none;
  `}
`;
const Input = styled.input`
  width: 100%;
  height: 50px;
  border: none;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  background: none;
  outline: none;
`;
const TopLine = styled.div<{ isfocused: boolean }>`
  position: relative;
  top: 17px;
  right: 1px;
  display: flex;
  flex: 1;
  height: 1px;
  transition: background-color 0.5s ease;
  background-color: ${(p) => (p.isfocused ? "#767676" : "#ed283c")};
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const ErrorText = styled.p`
  font-size: 14px;
  width: fit-content;
  color: #ed283c;
  margin-top: 5px;
  background-color: #fff;
  padding: 0 8px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    animation: ${fadeOut} 1s ease forwards;
    z-index: -1;
  }
`;

const Separator = styled.div<{ isfocused: boolean }>`
  width: 1px;
  height: 30px;
  background-color: #ed283c;
  position: absolute;
  left: 100px;
  bottom: 20px;
  transition: background-color 0.5s ease;
  background-color: ${(p) => (p.isfocused ? "#767676" : "#ed283c")};
`;
