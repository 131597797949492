import IconWrapper, { IconProps } from "./IconWrapper";

export default function TiktokIcon(props: IconProps) {
  const { width, height, color } = props;

  return (
    <IconWrapper
      width={width || "36"}
      height={height || "36"}
      viewBox={"0 0 36 36"}
    >
      <path
        d="M30.2012 10.035C28.6644 9.70619 27.2742 8.91215 26.23 7.76684C25.1858 6.62152 24.5414 5.18381 24.3891 3.66V3H19.0704V23.505C19.0715 24.4148 18.7783 25.302 18.2324 26.0407C17.6865 26.7794 16.9156 27.3323 16.0288 27.621C15.142 27.9097 14.1843 27.9196 13.2914 27.6493C12.3985 27.379 11.6157 26.8422 11.0537 26.115C10.5503 25.4713 10.242 24.7033 10.1638 23.898C10.0855 23.0927 10.2404 22.2821 10.6109 21.558C10.9814 20.8339 11.5528 20.225 12.2604 19.8004C12.968 19.3757 13.7836 19.1522 14.615 19.155C15.0748 19.1524 15.5323 19.2182 15.9716 19.35V14.1C15.4603 14.0384 14.9451 14.0133 14.43 14.025C12.5327 14.0743 10.6913 14.6609 9.1314 15.7129C7.57149 16.7649 6.36073 18.2367 5.64738 19.948C4.93403 21.6593 4.74905 23.5358 5.1151 25.3478C5.48115 27.1599 6.38234 28.8287 7.70831 30.15C9.06727 31.4943 10.8063 32.4152 12.7044 32.7957C14.6025 33.1762 16.574 32.9991 18.3684 32.2869C20.1628 31.5747 21.6991 30.3596 22.7822 28.7959C23.8652 27.2322 24.446 25.3906 24.4508 23.505V13.005C26.596 14.4959 29.1683 15.2935 31.8046 15.285V10.185C31.2867 10.1871 30.7701 10.1368 30.2629 10.035H30.2012Z"
        fill={color || "#ED283C"}
      />
    </IconWrapper>
  );
}
