import styled from "styled-components";
import PsyIcon from "../../assets/icons/psyIcon";
import HideComponentInMobile from "../../core/components/HideComponentInMobile";
import Inscripciones from "../../assets/images/inscripciones";
import Textinput from "../../core/components/Textinput";
import Pressable from "../../core/components/Pressable";
import InstagramIcon from "../../assets/icons/Instagram";
import FacebookIcon from "../../assets/icons/Facebook";
import TiktokIcon from "../../assets/icons/Tiktok";
import YoutubeIcon from "../../assets/icons/Youtube";
import TwitchIcon from "../../assets/icons/Twitch";
import WhatsappIcon from "../../assets/icons/Whatsapp";
import { BlankSpace } from "../../core/components/Layout";
import Button from "../../core/components/Button";
import useRegister from "./useRegister";
import BackgroundLayout from "../../assets/images/backgroundLayout.png";
import RadioBox from "../../core/components/RadioBox";
import Select from "../../core/components/Select";
import countries from "../../assets/countries.json";
import gameServers from "../../assets/gameServers.json";
import Phoneinput from "../../core/components/PhoneInput";
import { formatNumberPhoneMask } from "../../core/utils/formaters";

export default function Register() {
  const { formik } = useRegister();

  return (
    <Container>
      <HideInWeb>
        <BlankSpace height={20} />
        <PsyIcon />
      </HideInWeb>
      <LogoBackgroundContainer>
        <PsyIcon width="100%" opacity="0.04" />
      </LogoBackgroundContainer>
      <HeaderContainer>
        <HideComponentInMobile>
          <PsyIcon />
        </HideComponentInMobile>
        <TitleCenterContainer>
          <TitleContainer>
            <Inscripciones />
          </TitleContainer>
        </TitleCenterContainer>
        <HideComponentInMobile>
          <PsyIcon />
        </HideComponentInMobile>
      </HeaderContainer>
      <FormContainer>
        <Textinput
          title={"Nombre y apellido"}
          name="name"
          placeholder="Psysic eSport league"
          value={formik.values.name}
          onChange={(e) => formik.handleChange("name")(e.trim())}
          error={{
            message: formik.errors?.name,
            touched: formik.touched.name,
          }}
        />
        <Textinput
          title={"Nickname (ID)"}
          name="nickName"
          placeholder="PSYLEAGUE.GG"
          value={formik.values.nickName}
          onChange={(e) => formik.handleChange("nickName")(e.trim())}
          error={{
            message: formik.errors?.nickName,
            touched: formik.touched.nickName,
          }}
        />
        <BlankSpace height={6} />
        <Textinput
          title={"Correo"}
          name="email"
          placeholder="psyleague.gg@mail.com"
          value={formik.values.email}
          onChange={(e) => formik.handleChange("email")(e.trim())}
          error={{
            message: formik.errors?.email,
            touched: formik.touched.email,
          }}
        />
        <Phoneinput
          title={"Número de teléfono"}
          name="phone"
          placeholder="(123) 456-7890"
          value={formik.values.phone}
          phoneCode={formik.values.phoneCode}
          onChangeCode={formik.handleChange("phoneCode")}
          onChange={(e) =>
            formik.handleChange("phone")(formatNumberPhoneMask(e))
          }
          error={{
            message: formik.errors?.phone,
            touched: formik.touched.phone,
          }}
        />
        <Textinput
          title={"Usuario de instagram"}
          name="instagramUser"
          placeholder="@psyleague.gg"
          value={formik.values.instagramUser}
          onChange={(e) => formik.handleChange("instagramUser")(e.trim())}
          error={{
            message: formik.errors?.instagramUser,
            touched: formik.touched.instagramUser,
          }}
        />
        <Select
          title="País"
          name="country"
          options={countries.map((country) => ({
            value: country,
            text: country,
          }))}
          value={formik.values.country}
          onChange={formik.handleChange("country")}
          error={{
            message: formik.errors?.country,
            touched: formik.touched.country,
          }}
        />
        <Select
          title="Región del juego"
          name="serverRegion"
          options={gameServers.map((server) => ({
            value: server,
            text: server,
          }))}
          value={formik.values.serverRegion}
          onChange={formik.handleChange("serverRegion")}
          error={{
            message: formik.errors?.serverRegion,
            touched: formik.touched.serverRegion,
          }}
        />
        <BlankSpace height={24} />
        <RadioBox
          title="¿En qué modalidad te gustaría que se jugara el torneo?"
          name="typeOfGameVote"
          options={[
            { text: "Solitario", value: "1" },
            { text: "Dúos", value: "2" },
            { text: "Tríos", value: "3" },
            { text: "Escuadrones", value: "4" },
          ]}
          value={formik.values.typeOfGameVote}
          onChange={formik.handleChange("typeOfGameVote")}
          error={{
            message: formik.errors?.typeOfGameVote,
            touched: formik.touched.typeOfGameVote,
          }}
        />
        <BlankSpace height={24} />
        <T1>
          Para reclamar tus premios debes seguirnos en nuestras Redes Sociales
        </T1>
        <BlankSpace height={12} />
        <T2>SÍGUENOS</T2>
        <BlankSpace height={12} />
        <SocialIconsContainer>
          <Pressable
            onClick={() =>
              window.open("https://www.instagram.com/psyleague.gg/", "_blank")
            }
          >
            <InstagramIcon />
          </Pressable>
          <Pressable
            onClick={() =>
              window.open("https://www.facebook.com/psyleague.gg/", "_blank")
            }
          >
            <FacebookIcon />
          </Pressable>
          <Pressable
            onClick={() =>
              window.open("https://www.tiktok.com/@psyleague.gg", "_blank")
            }
          >
            <TiktokIcon />
          </Pressable>
          <Pressable
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCp4s-EOdCiP1m4Q3tJ69uxA",
                "_blank"
              )
            }
          >
            <YoutubeIcon />
          </Pressable>
          <Pressable
            onClick={() =>
              window.open("https://www.twitch.tv/psyleaguegg", "_blank")
            }
          >
            <TwitchIcon />
          </Pressable>
        </SocialIconsContainer>
        <BlankSpace height={32} />
        <T1>
          Recuerda que las claves del torneo se enviarán por el grupo de
          whatsapp, no olvides unirte
        </T1>
        <BlankSpace height={12} />
        <Pressable
          onClick={() =>
            window.open(
              "https://chat.whatsapp.com/BBD4m45BSJI925JDAdX62V",
              "_blank"
            )
          }
        >
          <WhatsappIcon width="60" height="60" />
        </Pressable>
        <BlankSpace height={40} />
        <ButtonContainer>
          <Button
            label="Finalizar Registro"
            onClick={() => {
              formik.submitForm();
            }}
          ></Button>
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  padding-bottom: 100px;
  background-image: url(${BackgroundLayout});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;
const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  max-width: 1200px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 600px;
  min-width: 340px;
`;
const TitleCenterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 400px;
  max-width: 600px;
  padding: 24px;
`;
const SocialIconsContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;
const T1 = styled.p`
  font-size: 20px;
  text-align: center;
  font-weight: 600;
`;
const T2 = styled.p`
  color: #ed283c;
  font-size: 22px;
  font-weight: 600;
`;
const ButtonContainer = styled.div`
  width: 300px;
`;
const LogoBackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  @media (max-width: 600px) {
    width: 200vw;
  }
`;
const HideInWeb = styled.div`
  display: none;
  flex-direction: column;
  @media (max-width: 768px) {
    display: flex;
  }
`;
