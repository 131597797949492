import Modal from "react-modal";

interface Props {
  isOpen: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
}

Modal.setAppElement("#root");

export default function CustomModal(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {}}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          background: "none",
        },
        overlay: {
          background: "none",
        },
      }}
      contentLabel="Example Modal"
    >
      {props.children}
    </Modal>
  );
}
