import { useFormik } from "formik";
import { useEffect } from "react";
import * as yup from "yup";
import useErrorFocusFormik from "./useErrorFocusFormik";

interface Props<InitialValues extends { [key: string]: any }> {
  initialValues: InitialValues;
  validationSchema: yup.AnySchema;
  onSubmit: (values: InitialValues) => void;
}

export default function useCustomFormik<
  InitialValues extends { [key: string]: any }
>(props: Props<InitialValues>) {
  const formik = useFormik<InitialValues>({
    initialValues: props.initialValues,
    validationSchema: props.validationSchema,
    onSubmit: props.onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });
  useErrorFocusFormik({ formik });

  useEffect(() => {
    formik.setTouched({});
    formik.setErrors({});
  }, [formik.values]);

  return {
    formik,
  };
}
