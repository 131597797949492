import styled, { keyframes } from "styled-components";

interface Props {
  title: string;
  name?: string;
  options: {
    text: string;
    value: string;
  }[];
  value: string;
  onChange: (e: string) => void;
  error?: {
    message?: string;
    touched?: boolean;
  };
}
export default function RadioBox(props: Props) {
  return (
    <Container>
      {props.error?.message && props.error?.touched && (
        <ErrorText id={props.name + "_error"}>
          *ERROR: {props.error.message}
        </ErrorText>
      )}
      <LabelContainer>
        <Label>{props.title}</Label>
      </LabelContainer>
      {props.options.map((option) => (
        <Option
          key={option.value}
          isselected={props.value === option.value}
          onClick={() => props.onChange(option.value)}
        >
          <Circle isselected={props.value === option.value}>
            <CircleContent isselected={props.value === option.value} />
          </Circle>
          <OptionText isselected={props.value === option.value}>
            {option.text}
          </OptionText>
        </Option>
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  user-select: none;
`;
const LabelContainer = styled.div`
  padding-left: 12px;
  padding-right: 6px;
`;
const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
`;
const Option = styled.div<{ isselected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
  border: 1px solid ${(p) => (p.isselected ? "#FFFFFF" : "#ed283c")};
  border-radius: 10px 1px 10px 1px;
  padding: 12px 15px;
`;
const Circle = styled.div<{ isselected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid ${(p) => (p.isselected ? "#FFFFFF" : "#ed283c")};
  transition: border 0.5s ease;
`;
const CircleContent = styled.div<{ isselected: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  opacity: ${(p) => (p.isselected ? 1 : 0)};
  transition: opacity 0.5s ease;
`;
const OptionText = styled.p<{ isselected: boolean }>`
  font-size: 16px;
  margin-left: 12px;
  color: ${(p) => (p.isselected ? "#FFFFFF" : "#ed283c")};
  transition: color 0.5s ease;
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const ErrorText = styled.p`
  font-size: 14px;
  width: fit-content;
  color: #ed283c;
  margin-top: 5px;
  background-color: #fff;
  padding: 0 8px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    animation: ${fadeOut} 1s ease forwards;
    z-index: -1;
  }
`;
