import { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import TriangleIcon from "../../assets/icons/Triangle";

interface Props {
  title: string;
  name?: string;
  value?: string;
  onChange: (e: string) => void;
  options: {
    value: string;
    text: string;
  }[];
  error?: {
    message?: string;
    touched?: boolean;
  };
}

export default function Select(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  function handleSelectOption(option: string) {
    setIsOpen(false);
    props.onChange(option);
  }

  return (
    <Container onClick={() => setIsOpen(!isOpen)} ref={ref}>
      {props.error?.message && props.error?.touched && (
        <ErrorText id={props.name + "_error"}>
          *ERROR: {props.error.message}
        </ErrorText>
      )}
      <TitleContainer>
        <Label>{props.title}</Label>
        <TopLine />
      </TitleContainer>
      <SelectSubContainer>
        <SelectedValue>{props.value || "Seleccionar"}</SelectedValue>
        <TriangleIcon />
        {isOpen && (
          <OptionsContainer>
            {props.options.map((option, index) => (
              <Option
                key={index}
                onClick={() => handleSelectOption(option.value)}
              >
                {option.text}
              </Option>
            ))}
          </OptionsContainer>
        )}
      </SelectSubContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;
`;
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
`;
const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
  margin-left: 12px;
  margin-right: 4px;
  color: #ed283c;
`;
const TopLine = styled.div`
  position: relative;
  top: 17px;
  right: 1px;
  display: flex;
  flex: 1;
  height: 1px;
  background-color: #ed283c;
`;
const SelectSubContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 17px;
  border: 1px solid #ed283c;
  border-top: none;
  border-radius: 10px 1px 10px 1px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  background: none;
`;
const SelectedValue = styled.div`
  display: flex;
  flex: 1;
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  border: 1px solid #ff818d;
  border-top: none;
  background: #93202b;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  max-height: 300px;
  overflow-y: auto;
`;

const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #fff2;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const ErrorText = styled.p`
  font-size: 14px;
  width: fit-content;
  color: #ed283c;
  margin-top: 5px;
  background-color: #fff;
  padding: 0 8px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    animation: ${fadeOut} 1s ease forwards;
    z-index: -1;
  }
`;
