import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { LoadingProvider } from "./core/context/loadingContext";
import Loading from "./core/components/Loading";

function App() {
  return (
    <LoadingProvider>
      <RouterProvider router={router} />
      <Loading />
    </LoadingProvider>
  );
}

export default App;
