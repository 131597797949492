export interface IconProps {
  width?: string;
  height?: string;
  color?: string;
  opacity?: string;
  style?: React.CSSProperties;
}
interface Props {
  children: React.ReactNode;
  width: string;
  height?: string;
  viewBox?: string;
  fill?: string;
  opacity?: string;
  style?: React.CSSProperties;
}

export default function IconWrapper(props: Props) {
  const { children, width, height, viewBox, fill, opacity, style } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity || "1"}
      style={style}
    >
      {children}
    </svg>
  );
}
