import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBYJf2NQrv8yzEKd4llLRSLlExOrRI9_KY",
  authDomain: "psysicleague.firebaseapp.com",
  projectId: "psysicleague",
  storageBucket: "psysicleague.appspot.com",
  messagingSenderId: "4105809869",
  appId: "1:4105809869:web:c137a9fdf85277c5139e84",
  measurementId: "G-687Z6S4QBF",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
