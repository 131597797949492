import IconWrapper, { IconProps } from "./IconWrapper";

export default function FacebookIcon(props: IconProps) {
  const { width, height, color } = props;

  return (
    <IconWrapper width={width || "36"} height={height} viewBox={"0 0 36 36"}>
      <path
        d="M20.0955 31.4955V19.2015H24.243L24.8595 14.388H20.0955V11.322C20.0955 9.93301 20.4825 8.98201 22.476 8.98201H25.002V4.69051C23.7729 4.5588 22.5376 4.4952 21.3015 4.50001C17.6355 4.50001 15.1185 6.73801 15.1185 10.8465V14.379H10.998V19.1925H15.1275V31.4955H20.0955Z"
        fill={color || "#ED283C"}
      />
    </IconWrapper>
  );
}
