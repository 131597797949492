import styled from "styled-components";

interface BlankSpaceProps {
  height?: number;
  width?: number;
}
interface MaxWidthContainerProps {
  justify?: string;
  align?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around";
  direction?: "column" | "row";
}

export const BlankSpace = styled.div<BlankSpaceProps>`
  width: ${(p) => (p.width ? `${p.width}px` : "100%")};
  height: ${(p) => (p.height ? `${p.height}px` : "100%")};
`;

export const MaxWidthContainer = styled.div<MaxWidthContainerProps>`
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: ${(p) => (p.direction ? `${p.direction}` : "column")};
  justify-content: ${(p) => (p.justify ? `${p.justify}` : "auto")};
  align-items: ${(p) => (p.align ? `${p.align}` : "auto")};
  box-sizing: border-box;
  gap: 5px;
`;
