import IconWrapper, { IconProps } from "./IconWrapper";

export default function TriangleIcon(props: IconProps) {
  const { width, height, color } = props;

  return (
    <IconWrapper
      width={width || "11"}
      height={height || "9"}
      viewBox={"0 0 9 7"}
    >
      <path
        d="M4.5 7L0.602886 0.25L8.39711 0.25L4.5 7Z"
        fill={color || "#FFFFFF"}
      />
    </IconWrapper>
  );
}
