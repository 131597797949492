import IconWrapper, { IconProps } from "./IconWrapper";

export default function YoutubeIcon(props: IconProps) {
  const { width, height, color } = props;

  return (
    <IconWrapper
      width={width || "36"}
      height={height || "36"}
      viewBox={"0 0 36 36"}
    >
      <path
        d="M33.2892 10.8045C33.1136 10.1706 32.7705 9.59252 32.2939 9.12776C31.8173 8.66301 31.2239 8.3278 30.5728 8.15551C28.1586 7.51051 18.5 7.50001 18.5 7.50001C18.5 7.50001 8.84302 7.48951 6.42723 8.10601C5.77653 8.28623 5.18437 8.62618 4.7076 9.09321C4.23083 9.56025 3.88546 10.1387 3.70465 10.773C3.06794 13.122 3.06177 17.994 3.06177 17.994C3.06177 17.994 3.0556 22.89 3.68769 25.215C4.04227 26.5005 5.0829 27.516 6.40565 27.8625C8.84456 28.5075 18.4769 28.518 18.4769 28.518C18.4769 28.518 28.1354 28.5285 30.5497 27.9135C31.201 27.7415 31.795 27.4071 32.2727 26.9433C32.7505 26.4795 33.0956 25.9024 33.2738 25.269C33.9121 22.9215 33.9167 18.051 33.9167 18.051C33.9167 18.051 33.9475 13.1535 33.2892 10.8045ZM15.4105 22.5075L15.4182 13.5075L23.4457 18.015L15.4105 22.5075Z"
        fill={color || "#ED283C"}
      />
    </IconWrapper>
  );
}
