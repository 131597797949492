import { createContext, useState } from "react";

interface ILoadingContext {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const loadingContext = createContext<ILoadingContext>({
  loading: false,
  setLoading: () => {},
});

const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <loadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </loadingContext.Provider>
  );
};

export { LoadingProvider, loadingContext };
