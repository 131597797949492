import styled, { keyframes } from "styled-components";
import RadioactiveIcon from "../../assets/icons/Radioactive";
import CustomModal from "./CustomModal";
import { useContext } from "react";
import { loadingContext } from "../context/loadingContext";
import { BlankSpace } from "./Layout";

export default function Loading() {
  const { loading } = useContext(loadingContext);

  return (
    <CustomModal isOpen={loading}>
      <Container>
        <SpinContainer>
          <RadioactiveIcon />
        </SpinContainer>
        <BlankSpace height={24} />
        <span>Loading...</span>
      </Container>
    </CustomModal>
  );
}

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ed283c;
  font-size: 24px;
  font-weight: 800;
  background: none;
  backdrop-filter: blur(5px);
  span {
    font-size: 32px;
  }
`;
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const SpinContainer = styled.div`
  animation: ${spin} 1s linear infinite;
  width: 222px;
  height: 222px;
`;
