import { createBrowserRouter } from "react-router-dom";
import Register from "./features/registerForm/Register.view";
import SuccessMessage from "./features/registerForm/SuccessMessage.view";
import PrivacyPolicy from "./features/policy/PrivacyPolicy";
import TermsAndConditions from "./features/policy/TermsAndConditions";
import DeleteAccount from "./features/policy/DeleteAccount";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Register />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/register/:id",
    element: <Register />,
  },
  {
    path: "/register/success",
    element: <SuccessMessage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/delete-account",
    element: <DeleteAccount />,
  },
]);
