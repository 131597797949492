import IconWrapper, { IconProps } from "./IconWrapper";

export default function RadioactiveIcon(props: IconProps) {
  const { width, height, color } = props;

  return (
    <IconWrapper
      width={width || "222"}
      height={height || "204"}
      viewBox={"0 0 111 102"}
    >
      <path
        d="M55.3455 42.9071C48.4851 42.9071 42.9185 48.4737 42.9185 55.3341C42.9185 62.1945 48.4851 67.7612 55.3455 67.7612C62.2059 67.7612 67.7726 62.1945 67.7726 55.3341C67.7726 48.4737 62.2059 42.9071 55.3455 42.9071Z"
        fill={color || "#ED283C"}
      />
      <path
        d="M55.3462 31.2635C61.8149 31.2635 67.6783 33.8035 71.9987 37.9458L81.6958 6.65861C73.8621 2.40944 64.889 0 55.3462 0C45.8034 0 36.8303 2.40944 28.9966 6.65861L38.6937 37.9458C43.0141 33.7916 48.8775 31.2635 55.3343 31.2635H55.3462Z"
        fill={color || "#ED283C"}
      />
      <path
        d="M103.963 81.7669C108.52 73.3872 110.68 64.3548 110.692 55.441L78.5624 49.0198C80.1529 54.8001 79.5713 61.162 76.4853 66.8354C73.3993 72.5208 68.3668 76.4614 62.6577 78.2655L85.5178 101.731C92.9954 96.8764 99.3929 90.1465 103.951 81.7669H103.963Z"
        fill={color || "#ED283C"}
      />
      <path
        d="M25.1746 101.731L48.0346 78.2655C42.3256 76.4614 37.293 72.5208 34.207 66.8354C31.121 61.1501 30.5394 54.7882 32.1299 49.0198L0 55.441C0 64.3548 2.18393 73.3872 6.72983 81.7669C11.2876 90.1465 17.6851 96.8764 25.1627 101.731H25.1746Z"
        fill={color || "#ED283C"}
      />
    </IconWrapper>
  );
}
