import styled from "styled-components";
import BackgroundLayout from "../../assets/images/backgroundLayout.png";
import PsyIcon from "../../assets/icons/psyIcon";
import { BlankSpace } from "../../core/components/Layout";
import Pressable from "../../core/components/Pressable";
import InstagramIcon from "../../assets/icons/Instagram";
import FacebookIcon from "../../assets/icons/Facebook";
import TiktokIcon from "../../assets/icons/Tiktok";
import YoutubeIcon from "../../assets/icons/Youtube";
import TwitchIcon from "../../assets/icons/Twitch";
import WhatsappIcon from "../../assets/icons/Whatsapp";
import registroExitoso from "../../assets/images/registro-exitoso.svg";

export default function SuccessMessage() {
  return (
    <Container>
      <LogoBackgroundContainer>
        <PsyIcon width="100%" opacity="0.04" />
      </LogoBackgroundContainer>
      <BodyContainer>
        <BodySubContainer>
          <PsyIcon />
          <BlankSpace height={40} />
          <RegistroExitosoImg src={registroExitoso} />
          <T1>REGISTRO</T1>
          <T2>EXITOSO</T2>
          <BlankSpace height={60} />
          <T3>VISÍTA NUESTRAS REDES PARA MANTENERTE INFORMADO</T3>
          <BlankSpace height={12} />
          <SocialIconsContainer>
            <Pressable
              onClick={() =>
                window.open("https://www.instagram.com/psyleague.gg/", "_blank")
              }
            >
              <InstagramIcon />
            </Pressable>
            <Pressable
              onClick={() =>
                window.open("https://www.facebook.com/psyleague.gg/", "_blank")
              }
            >
              <FacebookIcon />
            </Pressable>
            <Pressable
              onClick={() =>
                window.open("https://www.tiktok.com/@psyleague.gg", "_blank")
              }
            >
              <TiktokIcon />
            </Pressable>
            <Pressable
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCp4s-EOdCiP1m4Q3tJ69uxA",
                  "_blank"
                )
              }
            >
              <YoutubeIcon />
            </Pressable>
            <Pressable
              onClick={() =>
                window.open("https://www.twitch.tv/psyleaguegg", "_blank")
              }
            >
              <TwitchIcon />
            </Pressable>
            <Pressable
              onClick={() =>
                window.open(
                  "https://chat.whatsapp.com/BBD4m45BSJI925JDAdX62V",
                  "_blank"
                )
              }
            >
              <WhatsappIcon />
            </Pressable>
          </SocialIconsContainer>
        </BodySubContainer>
      </BodyContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  background-image: url(${BackgroundLayout});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;
const LogoBackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  @media (max-width: 600px) {
    width: 200vw;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-width: 600px;
  align-items: center;
  justify-content: center;
`;
const BodySubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
const T = styled.p`
  font-size: 60px;
  text-align: center;
  font-weight: 800;
  @media (max-width: 768px) {
    font-size: 42px;
  }
`;
const T1 = styled(T)`
  color: #ffffff;
`;
const T2 = styled(T)`
  color: #ed283c;
`;
const T3 = styled(T)`
  color: #ed283c;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
`;
const SocialIconsContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;
const RegistroExitosoImg = styled.img`
  max-width: 95%;
  position: absolute;
  top: 220px;
  /* @media (max-width: 768px) {
    top: 240px;
  } */
`;
