import styled from "styled-components";

export default function DeleteAccount() {
  return (
    <Container>
      <TextContainer>
        <strong>Delete Psy legague Account</strong>
        <p>
          If you want to delete your account, please send an email to
          aleoo7100@gmail.com with the subject "Delete Account" and your account
          (all your data) will be deleted.
        </p>
      </TextContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  * {
    color: #333;
    font-family: Arial, Helvetica, sans-serif;
  }
`;
const TextContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;
  text-align: justify;
  strong {
    font-size: 1.5rem;
    font-weight: bold;
  }
  p {
    font-size: 1rem;
    margin: 10px 0;
  }
  ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  li {
    font-size: 1rem;
    margin: 10px 0;
  }
  a {
    color: #333;
    text-decoration: none;
  }
`;
