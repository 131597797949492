import IconWrapper, { IconProps } from "./IconWrapper";

export default function TwitchIcon(props: IconProps) {
  const { width, height, color } = props;

  return (
    <IconWrapper
      width={width || "36"}
      height={height || "36"}
      viewBox={"0 0 36 36"}
    >
      <path
        d="M6.3975 4.5L4.5 9.354V29.1885H11.253V31.5L15.0495 32.775L18.6375 29.1885H24.1245L31.509 21.8025V4.5H6.3975ZM28.9755 20.5365L24.756 24.7575H18.003L14.4165 28.344V24.7575H8.7195V7.032H28.9755V20.5365ZM24.7575 11.886V19.2705H22.2255V11.886H24.7575ZM18.0045 11.886V19.2705H15.4725V11.886H18.0045Z"
        fill={color || "#ED283C"}
      />
    </IconWrapper>
  );
}
