import IconWrapper, { IconProps } from "./IconWrapper";

export default function WhatsappIcon(props: IconProps) {
  const { width, height, color } = props;

  return (
    <IconWrapper
      width={width || "36"}
      height={height || "36"}
      viewBox={"0 0 36 36"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6045 8.44951C26.357 7.19384 24.8728 6.19819 23.2378 5.52024C21.6028 4.84229 19.8495 4.49551 18.0795 4.50001C10.6575 4.50001 4.6155 10.5405 4.6125 17.9655C4.6125 20.3385 5.232 22.6545 6.4095 24.6975L4.5 31.674L11.6385 29.8005C13.6131 30.8754 15.8253 31.439 18.0735 31.44H18.0795C25.5 31.44 31.542 25.3995 31.545 17.9745C31.5501 16.2052 31.2045 14.4525 30.5281 12.8176C29.8518 11.1827 28.8581 9.69807 27.6045 8.44951ZM18.0795 29.1675H18.075C16.0703 29.1677 14.1024 28.6284 12.378 27.606L11.97 27.363L7.734 28.4745L8.8635 24.345L8.598 23.922C7.47718 22.1376 6.88385 20.0727 6.8865 17.9655C6.8895 11.7945 11.91 6.77401 18.084 6.77401C19.5544 6.77075 21.0108 7.05914 22.369 7.62249C23.7272 8.18584 24.9601 9.01298 25.9965 10.056C27.0384 11.0941 27.8643 12.3284 28.4263 13.6875C28.9884 15.0467 29.2755 16.5037 29.271 17.9745C29.268 24.1455 24.2475 29.1675 18.0795 29.1675ZM24.219 20.784C23.8815 20.6145 22.2285 19.8015 21.9195 19.689C21.612 19.5765 21.3885 19.521 21.1635 19.857C20.9385 20.193 20.2935 20.9505 20.097 21.1755C19.9005 21.4005 19.704 21.4275 19.368 21.2595C19.032 21.0915 17.9475 20.736 16.662 19.59C15.6615 18.6975 14.9865 17.5965 14.79 17.259C14.5935 16.9215 14.769 16.74 14.9385 16.572C15.09 16.422 15.2745 16.179 15.4425 15.9825C15.6105 15.786 15.666 15.6465 15.7785 15.4215C15.891 15.1965 15.8355 15 15.75 14.832C15.666 14.6625 14.9925 13.0065 14.712 12.333C14.4405 11.6805 14.163 11.7675 13.956 11.7585C13.7416 11.7497 13.5271 11.7457 13.3125 11.7465C13.1419 11.7509 12.974 11.7904 12.8194 11.8627C12.6648 11.935 12.5268 12.0384 12.414 12.1665C12.105 12.504 11.2365 13.317 11.2365 14.973C11.2365 16.629 12.4425 18.2295 12.6105 18.4545C12.7785 18.6795 14.9835 22.077 18.3585 23.535C19.1625 23.8815 19.7895 24.0885 20.277 24.2445C21.0825 24.501 21.816 24.4635 22.3965 24.378C23.043 24.282 24.387 23.565 24.6675 22.779C24.948 21.993 24.948 21.3195 24.864 21.1785C24.78 21.0375 24.5535 20.952 24.219 20.784Z"
        fill={color || "#ED283C"}
      />
    </IconWrapper>
  );
}
