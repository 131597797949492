export function formatNumberPhoneMask(number: string) {
  // Remove all non-digit characters from the input phone number
  const cleanedNumber = number.replace(/\D/g, "");
  // Format the phone number based on its length
  let formattedNumber = "";
  if (cleanedNumber.length < 4) {
    formattedNumber = cleanedNumber;
  } else if (cleanedNumber.length < 7) {
    formattedNumber = `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(
      3
    )}`;
  } else {
    formattedNumber = `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(
      3,
      6
    )}-${cleanedNumber.slice(6, 11)}`;
  }

  return formattedNumber;
}

export function formatBirthdayMask(birthday: string) {
  // Remove all non-digit characters from the input date of birth
  const cleanedDate = birthday.replace(/\D/g, "");
  // Format the date of birth based on its length
  let formattedDate = "";
  if (cleanedDate.length < 3) {
    formattedDate = cleanedDate;
  } else if (cleanedDate.length < 5) {
    formattedDate = `${cleanedDate.slice(0, 2)}/${cleanedDate.slice(2)}`;
  } else {
    formattedDate = `${cleanedDate.slice(0, 2)}/${cleanedDate.slice(
      2,
      4
    )}/${cleanedDate.slice(4, 8)}`;
  }
  return formattedDate;
}
