import styled from "styled-components";

interface Props {
  label: string;
  onClick: () => void;
}

export default function Button(props: Props) {
  return (
    <Container onClick={props.onClick}>
      <Label>{props.label}</Label>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #ed283c;
  border-radius: 10px 1px 10px 1px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  user-select: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 12px 1px rgba(237, 40, 60, 0.88);
  }
  & > * {
    cursor: pointer;
  }
`;
const Label = styled.label`
  font-size: 22px;
  color: #fff;
  background: none;
`;
