import {useEffect, useState} from "react";
import {FormikContextType} from "formik";
import scrollToElement from "scroll-to-element";

export type Alignment = "top" | "middle" | "bottom" | undefined;

export type ErrorFocusFormikProps = {
  offset?: number;
  align?: Alignment;
  ease?: string | undefined;
  duration?: number | undefined;
  focusDelay?: number;
} & {formik: FormikContextType<any>};

export default function useErrorFocusFormik(initialProps: ErrorFocusFormikProps) {
  const props = {
    offset: initialProps.offset || 0,
    align: initialProps.align || ("top" as Alignment),
    focusDelay: initialProps.focusDelay || 200,
    ease: initialProps.ease || "linear",
    duration: initialProps.duration || 1000,
  };
  const [timer, setTimer] = useState<number | NodeJS.Timeout | null>(null);

  useEffect(() => {
    const {isSubmitting, isValidating, errors} = initialProps.formik;

    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[id="${keys[0]}_error"]`;
      const errorElement = document.querySelector(selector) as HTMLInputElement;
      if (errorElement) {
        const {offset, ease, duration, focusDelay, align} = props;
        scrollToElement(errorElement, {
          offset,
          ease,
          duration,
          align,
        });

        if (duration) {
          setTimer(setTimeout(() => errorElement.focus(), duration + focusDelay));
        }
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [initialProps]);

  return {};
}
