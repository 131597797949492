import { useState } from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  title: string;
  type?: string;
  name?: string;
  placeholder: string;
  value: string;
  onChange: (e: string) => void;
  error?: {
    message?: string;
    touched?: boolean;
  };
}
export default function Textinput(props: Props) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Container>
      {props.error?.message && props.error?.touched && (
        <ErrorText id={props.name + "_error"}>
          *ERROR: {props.error.message}
        </ErrorText>
      )}
      <TitleContainer>
        <Label isfocused={isFocused}>{props.title}</Label>
        <TopLine isfocused={isFocused} />
      </TitleContainer>
      <Input
        type={props.type || "text"}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
`;
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
`;
const Label = styled.label<{ isfocused: boolean }>`
  font-size: 18px;
  font-weight: 600;
  color: ${(p) => (p.isfocused ? "#FFFFFF" : "#ed283c")};
  transition: color 0.5s ease;
  margin-left: 12px;
  margin-right: 4px;
`;
const TopLine = styled.div<{ isfocused: boolean }>`
  position: relative;
  top: 17px;
  right: 1px;
  display: flex;
  flex: 1;
  height: 1px;
  transition: background-color 0.5s ease;
  background-color: ${(p) => (p.isfocused ? "#767676" : "#ed283c")};
`;
const Input = styled.input`
  width: 100%;
  height: 50px;
  margin-top: 17px;
  border: 1px solid #ed283c;
  border-top: none;
  border-radius: 10px 1px 10px 1px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  background: none;
  transition: border 0.5s ease;
  &:focus {
    outline: none;
    border: 1px solid #767676;
    border-top: none;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const ErrorText = styled.p`
  font-size: 14px;
  width: fit-content;
  color: #ed283c;
  margin-top: 5px;
  background-color: #fff;
  padding: 0 8px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    animation: ${fadeOut} 1s ease forwards;
    z-index: -1;
  }
`;
