import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

export default function HideComponentInMobile({ children }: Props) {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  //hide component in mobile
  @media (max-width: 768px) {
    display: none;
  }
`;
