import styled from "styled-components";

interface Props {
  onClick: () => void;
  children: React.ReactNode;
}

export default function Pressable(props: Props) {
  return <Container onClick={props.onClick}>{props.children}</Container>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
  border-radius: 10px;
  &:hover {
    box-shadow: 0px 0px 12px -2px rgba(237, 40, 60, 0.88);
  }
`;
