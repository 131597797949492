import * as yup from "yup";
import useCustomFormik from "../../core/hooks/useCustomFormik";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { useContext, useEffect } from "react";
import { loadingContext } from "../../core/context/loadingContext";
import { useNavigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function useRegister() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { setLoading } = useContext(loadingContext);
  const { formik } = useCustomFormik<typeof initValues>({
    initialValues: initValues,
    onSubmit: onSubmit,
    validationSchema: schemaValidation,
  });

  useEffect(() => {
    const keys = Object.keys(formik.errors);
    if (keys.length > 0) {
      Sentry.captureException({
        error: JSON.stringify({ errors: formik.errors }),
        data: JSON.stringify({
          ...formik.values,
          createdAt: new Date(),
        }),
      });
    }
  }, [formik.errors]);

  async function onSubmit() {
    try {
      console.log("test");
      setLoading(true);
      await addDoc(collection(db, "users"), {
        ...formik.values,
        sourceUrl: id || "",
        createdAt: new Date(),
      });
      formik.resetForm();
      setTimeout(() => {
        navigate("/register/success");
      }, 1000);
    } catch (error) {
      Sentry.captureException({
        originalError: error,
        error: JSON.stringify({ error }),
        data: JSON.stringify({
          ...formik.values,
          createdAt: new Date(),
        }),
      });
      alert("Error al enviar el formulario, vuelve a intentarlo mas tarde");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  return { formik };
}

const schemaValidation = yup.object().shape({
  name: yup.string().required("El Nombre es obligatorio"),
  nickName: yup.string().required("El Nickname es obligatorio"),
  email: yup
    .string()
    .email("El formato de correo es incorrecto")
    .required("El Correo es obligatorio"),
  phone: yup
    .string()
    .required("El número de Teléfono es obligatorio")
    .matches(
      /^\(\d{3}\) \d{3}-\d{1,5}$/,
      "el numero de telefono debe tener minimo 7 digitos y maximo 11"
    ),
  country: yup.string().required("El País es obligatorio"),
  serverRegion: yup.string().required("La Región es obligatoria"),
  typeOfGameVote: yup.string().required("La votacion es obligatoria"),
  instagramUser: yup
    .string()
    .required("El Usuario de Instagram es obligatorio"),
});

const initValues = {
  name: "",
  nickName: "",
  email: "",
  phone: "",
  phoneCode: "+57",
  country: "",
  serverRegion: "",
  instagramUser: "",
  typeOfGameVote: "",
};
